import { Component } from '@angular/core';
import { NavigationService } from './shared/services/navigation.service';
import { ThemeService } from './shared/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Calendall';

  constructor(
    private navigationService: NavigationService,
    private themeService: ThemeService
  ) {
    console.debug(
      'Navigation service started to record navigation events',
      navigationService
    );
  }

  ngOnInit() {
    document.body.classList.add(this.themeService.getTheme() + '-theme');
    document.documentElement.setAttribute(
      'data-bs-theme',
      this.themeService.getTheme()
    );
  }
}
