import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {SessionService} from "../../shared/services/session.service";
import {filter, map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsGuard {

  constructor(
    public sessionService: SessionService,
    public router: Router,
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.sessionService.currentUser$.pipe(map(
      user => {
        return user.permissions.analytics;
      }
    )).pipe(filter(
      it => it
    ));
  }

}
