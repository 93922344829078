import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from '@app/app.module';
import {environment} from './environments/environment';
import {I18n} from "aws-amplify/utils";
import {Amplify} from "aws-amplify";
import {translations} from "@aws-amplify/ui-angular";
import {ResourcesConfig} from "@aws-amplify/core";

fetch('/assets/aws-exports.json', {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }
).then(async response => {

  const awsConfig: ResourcesConfig = await response.json();
  Amplify.configure(awsConfig);

  I18n.putVocabularies(translations);
  I18n.setLanguage('de');


  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});

