import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from "./shared/components/not-found/not-found.component";
import {TenantManagementGuard} from "./tenant-management/service/tenant-management-guard.service";
import {AnalyticsGuard} from "./analytics/services/analytics-guard.service";
import {AuthenticationGuard} from "./shared/services/authentication-guard.service";

const routes: Routes = [
  {
    path: 'aam',
    loadChildren: () => import('./account-and-access-management/account-and-access-management.module').then(m => m.AccountAndAccessManagementModule),
    canLoad: [AuthenticationGuard]
  },
  {
    path: 'cam',
    loadChildren: () => import('./customer-appointment-management/customer-and-appointment-management.module').then(m => m.CustomerAndAppointmentManagementModule),
    canLoad: [AuthenticationGuard]
  },
  {
    path: 'die',
    loadChildren: () => import('./data-import-export/data-import-export.module').then(m => m.DataImportExportModule),
    canLoad: [AuthenticationGuard]
  },
  {
    path: 'tm',
    loadChildren: () => import('./tenant-management/tenant-management.module').then(m => m.TenantManagementModule),
    canLoad: [AuthenticationGuard],
    canActivate: [TenantManagementGuard]
  },
  {
    path: 'analytics',
    loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
    canLoad: [AuthenticationGuard],
    canActivate: [AnalyticsGuard]
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
  },
  {
    path: 'signup',
    pathMatch: 'full',
    redirectTo: '/public/self-signup'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/cam/appointment-calendar'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
